import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import RichText from '~components/RichText';
import Container from '~components/Container';

const useStyles = makeStyles(() => ({
  media: {
    maxWidth: '100%',
    display: 'flex',
    margin: '0 auto'
  },
  buttonStyle: props => ({
    textTransform: 'none',
    fontWeight: '500',
    fontSize: '20px',
    lineHeight: '30px',
    padding: '15px 52px',
    margin: '0 auto',
    marginTop: '50px',
    boxShadow: 'unset',
    backgroundColor: props.button_bg_color || '#458FCE',
    color: props.button_title_color || '#fff',
    '&:hover': {
      boxShadow: 'unset',
      backgroundColor: props.button_bg_color || '#458FCE',
      opacity: '0.8'
    }
  }),
  containerSpacing: {
    marginBottom: '100px',
    marginTop: '100px'
  },
  gridSpacing: {
    marginTop: '70px'
  }
}));

const AboutTeam = ({
  section_title,
  members,
  section_subtitle,
  button_bg_color,
  button_title,
  button_link,
  button_title_color
}) => {
  const classes = useStyles({ button_bg_color, button_title_color });
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.only('xs'));

  return (
    <Container className={classes.containerSpacing}>
      <RichText
        html={section_title && section_title.html}
        mode="teamPageJobSectionTitle"
        verticalSpacing={0}
      />
      <RichText
        html={section_subtitle && section_subtitle.html}
        mode="teamPageFounderText"
        verticalSpacing={0}
      />
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="flex-start"
        spacing={isXs ? 2 : 6}
        className={classes.gridSpacing}
      >
        {members &&
          members.map(member => (
            <Grid item xs={12} sm={6} md={3} key={member && member.name && member.name.html}>
              <img
                src={member && member.avatar && member.avatar.url}
                alt={member && member.avatar && member.avatar.alt}
                className={classes.media}
              />
              <RichText
                html={member && member.name && member.name.html}
                mode="teamPageMemberName"
                verticalSpacing={0}
              />
            </Grid>
          ))}
      </Grid>
      <Button
        variant="contained"
        className={classes.buttonStyle}
        href={button_link && button_link.url}
        target="_blank"
      >
        {button_title}
      </Button>
    </Container>
  );
};

AboutTeam.propTypes = {
  section_title: PropTypes.shape({
    html: PropTypes.string.isRequired
  }).isRequired,
  section_subtitle: PropTypes.shape({
    html: PropTypes.string.isRequired
  }).isRequired,
  members: PropTypes.arrayOf(
    PropTypes.shape({
      avatar: PropTypes.shape({
        url: PropTypes.string.isRequired,
        alt: PropTypes.string
      }).isRequired,
      name: PropTypes.shape({
        html: PropTypes.string.isRequired
      }).isRequired
    }).isRequired
  ).isRequired,
  button_bg_color: PropTypes.string.isRequired,
  button_title: PropTypes.string.isRequired,
  button_link: PropTypes.shape({
    url: PropTypes.string.isRequired
  }).isRequired,
  button_title_color: PropTypes.string.isRequired
};
export default AboutTeam;
